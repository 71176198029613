import _ from 'lodash'
import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import {
	Fetch,
	IFetchApi,
	SiteFeatureConfigSymbol,
	ILogger,
	LoggerSymbol,
	SdkHandlersProvider,
	BrowserWindowSymbol,
} from '@wix/thunderbolt-symbols'
import { SessionManagerSiteConfig, DynamicSessionModel, ISessionManager, SessionHandlers, Instance } from './types'
import { name, DynamicSessionModelSymbol } from './symbols'

const sessionManagerFactory = (
	browserWindowSymbol: Window,
	siteFeatureConfig: SessionManagerSiteConfig,
	fetchApi: IFetchApi,
	logger: ILogger,
	dynamicSessionModel?: DynamicSessionModel
): ISessionManager & SdkHandlersProvider<SessionHandlers> => {
	let sessionTimeoutPointer: number

	const _onLoadSessionCallbacks: Array<(results: { [appDefinitionId: string]: Instance }) => void> = []

	const addLoadSessionCallback = (callback: (results: { [appDefinitionId: string]: Instance }) => void) => {
		_onLoadSessionCallbacks.push(callback)
	}

	const _sessionModel: Partial<SessionManagerSiteConfig['cachedSession'] & DynamicSessionModel> = {
		...siteFeatureConfig.cachedSession,
		...dynamicSessionModel,
	}

	const getAppInstanceByAppDefId = (
		appDefId: string,
		sessionModel: Partial<SessionManagerSiteConfig['cachedSession'] & DynamicSessionModel>
	): string | undefined => {
		const { instance } = (sessionModel.apps && sessionModel.apps[appDefId]) || {}
		return instance
	}

	const expirationTime = 30 * 60 * 1000 // thirty minutes

	const fireSessionLoadCallbacks = () => {
		const instances = _.mapValues(_sessionModel.apps, 'instance')
		_.forEach(_onLoadSessionCallbacks, (callback) => callback(instances))
	}

	const loadNewSession = async () => {
		try {
			const newSession = await fetchApi.getJson<DynamicSessionModel>(siteFeatureConfig.dynamicModelApiUrl)
			Object.assign(_sessionModel, newSession)
			fireSessionLoadCallbacks()
		} catch (error) {
			logger.captureError(error, {})
		}

		setSessionTimeout()
	}

	const setSessionTimeout = () => {
		if (!browserWindowSymbol) {
			return
		}

		if (sessionTimeoutPointer) {
			browserWindowSymbol.clearTimeout(sessionTimeoutPointer)
		}

		sessionTimeoutPointer = browserWindowSymbol.setTimeout(loadNewSession, expirationTime)
	}

	// set initial timeout for refresh
	setSessionTimeout()

	return {
		getAppInstanceByAppDefId(appDefId: string) {
			return getAppInstanceByAppDefId(appDefId, _sessionModel)
		},
		getSiteMemberId() {
			return _sessionModel.siteMemberId
		},
		getVisitorId() {
			return _sessionModel.visitorId
		},
		loadNewSession,
		getSdkHandlers: () => ({
			onLoadSession: (callback: (results: { [appDefinitionId: string]: Instance }) => void) => {
				addLoadSessionCallback(callback)
			},
		}),
	}
}

export const SessionManager = withDependencies(
	[
		BrowserWindowSymbol,
		named(SiteFeatureConfigSymbol, name),
		Fetch,
		LoggerSymbol,
		optional(DynamicSessionModelSymbol),
	],
	sessionManagerFactory
)
