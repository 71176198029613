import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { BusinessLogger } from './businessLogger'
import { BusinessLoggerSsr } from './businessLoggerSsr'
import { BsiManagerSymbol, BusinessLoggerSymbol, name, PageNumberSymbol } from './symbols'
import { LifeCycle, WixCodeSdkHandlersProviderSym } from '@wix/thunderbolt-symbols'
import { bsiSdkHandlersProvider } from './bsiSdkHandlersProvider'
import { BsiManager } from './bsiManager'
import { PageNumberHandler } from './pageNumber'

export const site: ContainerModuleLoader = (bind) => {
	const businessLoggerByEnv = process.env.browser ? BusinessLogger : BusinessLoggerSsr

	bind(BusinessLoggerSymbol).to(businessLoggerByEnv)
	bind(BsiManagerSymbol).to(BsiManager)
}

export const page: ContainerModuleLoader = (bind, bindAll) => {
	bind(WixCodeSdkHandlersProviderSym).to(bsiSdkHandlersProvider)
	bindAll([PageNumberSymbol, LifeCycle.PageDidMountHandler], PageNumberHandler)
}

export { BusinessLogger, IBsiManager, IPageNumber } from './types'
export { BusinessLoggerSymbol, BsiManagerSymbol, PageNumberSymbol, name }
