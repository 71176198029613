import { withDependencies } from '@wix/thunderbolt-ioc'
import { ICurrentRouteInfo, RouteInfo } from './types'

const currentRouteInfo = (): ICurrentRouteInfo => {
	let routeInfo: RouteInfo

	return {
		getCurrentRouteInfo: () => {
			return routeInfo ? routeInfo : null
		},
		updateCurrentRouteInfo: (nextRouteInfo: RouteInfo) => {
			routeInfo = nextRouteInfo
		},
		updateRouteInfoUrl: (parsedUrl: URL) => {
			if (routeInfo) {
				routeInfo.parsedUrl = parsedUrl
			}
		},
	}
}

export const CurrentRouteInfo = withDependencies([], currentRouteInfo)
