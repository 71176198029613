import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { name } from './symbols'
import { ClickHandlerRegistrar } from './clickHandlerRegistrar'
import { IAppDidMountHandler, LifeCycle } from '@wix/thunderbolt-symbols'

export const site: ContainerModuleLoader = (bind) => {
	bind<IAppDidMountHandler>(LifeCycle.AppDidMountHandler).to(ClickHandlerRegistrar)
}

export { name }
