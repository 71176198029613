import {
	IAppWillLoadPageHandler,
	IPageDidMountHandler,
	IPageDidUnmountHandler,
	IPropsStore,
	LifeCycle,
	Props,
} from '@wix/thunderbolt-symbols'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { PageProviderSymbol, IPageProvider } from 'feature-pages'

const addPageMountAndUnmountHandlers = async (
	pageId: string,
	contextId: string,
	props: IPropsStore,
	pageReflectorProvider: IPageProvider
) => {
	const pageReflector = await pageReflectorProvider(contextId)
	const pageDidMountHandlers = pageReflector.getAllImplementersOf<IPageDidMountHandler>(LifeCycle.PageDidMountHandler)
	const pageDidUnmountHandlers = pageReflector.getAllImplementersOf<IPageDidUnmountHandler>(
		LifeCycle.PageDidUnmountHandler
	)

	props.update({
		[pageId]: {
			key: contextId,
			pageDidMount: (page: HTMLElement) => {
				if (page) {
					pageDidMountHandlers.forEach((pageDidMountHandler) =>
						pageDidMountHandler.pageDidMount(pageId, page)
					)
				} else {
					pageDidUnmountHandlers.forEach((pageDidUnmountHandler) => pageDidUnmountHandler.pageDidUnmount())
				}
			},
		},
	})
}

export const PageMountUnmountSubscriber = withDependencies(
	[Props, PageProviderSymbol],
	(props: IPropsStore, pageReflectorProvider: IPageProvider): IAppWillLoadPageHandler => ({
		appWillLoadPage: ({ pageId, contextId }) =>
			addPageMountAndUnmountHandlers(pageId, contextId, props, pageReflectorProvider),
	})
)
