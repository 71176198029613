import { withDependencies } from '@wix/thunderbolt-ioc'
import { WixBiSessionSymbol, WixBiSession } from '@wix/thunderbolt-symbols'
import { SessionManagerSymbol, ISessionManager } from 'feature-session-manager'
import { createBusinessLoggerFactory } from './businessLoggerCommon'
import * as Types from './types'
import { BsiManagerSymbol } from './symbols'
import { IBsiManager } from './types'

const businessLoggerFactory = (
	wixBiSession: WixBiSession,
	sessionManager: ISessionManager,
	bsiManager: IBsiManager
): BusinessLogger => {
	const biLoggerFactory = createBusinessLoggerFactory(wixBiSession, sessionManager).updateDefaults({
		bsi: () => {
			return bsiManager.getBsi()
		},
	})

	return {
		logger: biLoggerFactory.logger(),
	}
}

export type BusinessLogger = Types.BusinessLogger
export const BusinessLogger = withDependencies(
	[WixBiSessionSymbol, SessionManagerSymbol, BsiManagerSymbol],
	businessLoggerFactory
)
