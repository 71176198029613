import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	IAppWillMountHandler,
	SiteFeatureConfigSymbol,
	ViewerModel,
	ViewerModelSym,
	BrowserWindow,
	BrowserWindowSymbol,
} from '@wix/thunderbolt-symbols'
import { IConsentPolicy, ConsentPolicySiteConfig, ConsentPolicyUpdatesListener } from './types'
import { name } from './symbols'
import { without } from 'lodash'

// This loads the consentPolicyManager client script, as part of the feature bundle
import '@wix/cookie-consent-policy-client'

const consentPolicyBrowserFactory = (
	config: ConsentPolicySiteConfig,
	viewerModel: ViewerModel,
	window: BrowserWindow
): IConsentPolicy & IAppWillMountHandler => {
	let listeners: Array<ConsentPolicyUpdatesListener> = []

	return {
		getCurrentConsentPolicy() {
			return window!.consentPolicyManager!.getCurrentConsentPolicy()
		},
		_getConsentPolicyHeader() {
			return window!.consentPolicyManager!._getConsentPolicyHeader()
		},
		setConsentPolicy(policy) {
			return new Promise((resolve, reject) => {
				window!.consentPolicyManager!.setConsentPolicy(policy, resolve, reject)
			})
		},
		resetConsentPolicy() {
			window!.consentPolicyManager!.resetPolicy()
			return Promise.resolve()
		},
		registerToChanges(listener) {
			listeners.push(listener)
			return () => {
				listeners = without(listeners, listener)
			}
		},
		appWillMount: () => {
			window!.consentPolicyManager!.init({
				baseUrl: viewerModel.site.externalBaseUrl,
				consentPolicy: config.siteConsentPolicy,
			})

			const onConsentPolicyChanged = (event: CustomEvent): void => {
				const policyDetails = event.detail
				const policyHeaderObject = window!.consentPolicyManager!._getConsentPolicyHeader()
				listeners.forEach((listener) => listener(policyDetails, policyHeaderObject))
			}

			// @ts-ignore
			window!.addEventListener('consentPolicyChanged', onConsentPolicyChanged)
		},
	}
}

export const ConsentPolicyBrowser = withDependencies(
	[named(SiteFeatureConfigSymbol, name), ViewerModelSym, BrowserWindowSymbol],
	consentPolicyBrowserFactory
)
