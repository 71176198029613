import { withDependencies, multi } from '@wix/thunderbolt-ioc'
import { PopHistoryStateHandler, UrlChangeHandlerForPage, UrlChangeListenerSymbol } from './symbols'
import {
	IAppWillMountHandler,
	CurrentRouteInfoSymbol,
	BrowserWindowSymbol,
	BrowserWindow,
	ViewerModelSym,
	ViewerModel,
} from '@wix/thunderbolt-symbols'
import { IPageProvider, PageProviderSymbol } from 'feature-pages'
import {
	IUrlHistoryPopStateHandler,
	IUrlHistoryManager,
	IUrlChangeListener,
	IUrlChangeHandler,
	ICurrentRouteInfo,
	IUrlHistoryState,
} from './types'

export const UrlChangeListener = withDependencies(
	[PageProviderSymbol, CurrentRouteInfoSymbol],
	(pageProvider: IPageProvider, currentRouteInfo: ICurrentRouteInfo): IUrlChangeListener => {
		return {
			onUrlChange: async (url) => {
				const routeInfo = currentRouteInfo.getCurrentRouteInfo()
				if (routeInfo) {
					currentRouteInfo.updateRouteInfoUrl(url)
					const page = await pageProvider(routeInfo.pageId)
					const pageHandlers = page.getAllImplementersOf<IUrlChangeHandler>(UrlChangeHandlerForPage)
					return Promise.all(pageHandlers.map((handler) => handler.onUrlChange(url)))
				}
			},
		}
	}
)

export const UrlHistoryManager = withDependencies(
	[BrowserWindowSymbol, ViewerModelSym, UrlChangeListenerSymbol],
	(
		browserWindow: BrowserWindow,
		viewerModel: ViewerModel,
		urlChangeListener: IUrlChangeListener
	): IUrlHistoryManager => ({
		pushUrlState: (parsedUrl: URL) => {
			if (!browserWindow || !browserWindow.history) {
				return
			}
			const url = parsedUrl.toString()
			const currentUrl = new URL(browserWindow.location.href)
			parsedUrl.searchParams.sort()
			currentUrl.searchParams.sort()
			if (currentUrl.toString() === parsedUrl.toString()) {
				return
			}
			const historyState: IUrlHistoryState = { scrollY: browserWindow.scrollY }

			browserWindow.history.replaceState(historyState, '', currentUrl.toString())
			browserWindow.history.pushState(null, '', url)
			urlChangeListener.onUrlChange(new URL(url))
		},
		getHistoryState: () => {
			if (!browserWindow || !browserWindow.history) {
				return null
			}
			return browserWindow.history.state as IUrlHistoryState
		},

		updateHistoryState: (newHistory?: IUrlHistoryState, scrollRestoration?: ScrollRestoration) => {
			if (!browserWindow || !browserWindow.history) {
				return
			}
			if (scrollRestoration) {
				browserWindow.history.scrollRestoration = scrollRestoration
			}

			if (newHistory) {
				const currentUrl = new URL(browserWindow.location.href)
				currentUrl.searchParams.sort()

				browserWindow.history.replaceState(newHistory, '', currentUrl.toString())
			}

			return
		},

		getParsedUrl: () => {
			if (browserWindow) {
				return new URL(browserWindow.location.href)
			} else {
				return new URL(viewerModel.requestUrl)
			}
		},
	})
)

export const PopStateListener = withDependencies(
	[multi(PopHistoryStateHandler), BrowserWindowSymbol, UrlChangeListenerSymbol],
	(
		popStateHandlers: Array<IUrlHistoryPopStateHandler>,
		browserWindow: BrowserWindow,
		urlChangeListener: IUrlChangeListener
	): IAppWillMountHandler => ({
		appWillMount: () => {
			if (!browserWindow) {
				return
			}
			browserWindow.addEventListener('popstate', async () => {
				const href = browserWindow.location.href
				await Promise.all(popStateHandlers.map((handler) => handler.onPopState(new URL(href))))
				await urlChangeListener.onUrlChange(new URL(href))
			})
		},
	})
)
