import { PublishMethods, factory } from '@wix/web-bi-logger/dist/src/logger'
import { FactoryOptions, BaseFactory } from './types'

export { Logger } from '@wix/web-bi-logger/dist/src/types'

/**
 * Base BI logger factory, should be used as a basis for any BI logger in the Viewer
 *
 * - Initialized with base defaults (which are supported globally in the BI schema).
 * - Any additional defaults should be added via specialized factories, like FedOps,
 *   and only after making sure the BI schema supports them.
 *
 * Please use #bi-logger-support for any questions
 */
const createBaseBiLoggerFactory = ({
	biStore,
	sessionManager,
	useBatch = !!process.env.browser,
	publishMethod = PublishMethods.Auto,
	endpoint,
}: FactoryOptions): BaseFactory =>
	factory({ useBatch, publishMethod, endpoint })
		.setMuted(false) // TODO mute if suppressbi=true
		.withUoUContext({
			msid: biStore.msid,
			visitorId: () => (sessionManager && sessionManager.getVisitorId()) || (biStore.visitorId as string),
			siteMemberId: () =>
				(sessionManager && sessionManager.getSiteMemberId()) || (biStore.siteMemberId as string),
		})
		.updateDefaults({
			vsi: biStore.viewerSessionId,
			rid: biStore.requestId,
			_av: `thunderbolt-${process.env.APP_VERSION}`,
		})

/**
 * BI logger factory for FedOps
 *
 * - Initialized with base defaults + defaults which are supported by FedOps BI events
 *   https://bo.wix.com/bi-catalog-webapp/#/sources/72
 *
 * - Any additional defaults should be added only after making sure the BI schema supports them
 *
 * Please use #bi-logger-support for any questions
 */
const createBiLoggerFactoryForFedops = (options: FactoryOptions) => {
	const { biStore } = options

	return createBaseBiLoggerFactory(options).updateDefaults({
		ts: () => Date.now() - biStore.initialTimestamp,
		is_rollout: biStore.is_rollout,
		is_cached: biStore.isCached,
		dc: biStore.dc,
		ish: biStore.is_headless,
	})
}

export const commonBiLoggerFactory = {
	createBaseBiLoggerFactory,
	createBiLoggerFactoryForFedops,
}
