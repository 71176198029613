import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import { HeadContentSymbol, IHeadContent, SiteFeatureConfigSymbol, HeadContentType } from '@wix/thunderbolt-symbols'
import { MultilingualSymbol, IMultilingual } from 'feature-multilingual'
import { SiteLevelSeoData, ISeoSiteApi, SeoPageConfig, SeoTagsPayload } from './types'
import { name } from './symbols'
import { DEFAULT_STATUS_CODE } from './api'

const seoSiteFactory = (
	siteLevelSeoData: SiteLevelSeoData,
	headUtils: IHeadContent,
	multilingualApi?: IMultilingual
): ISeoSiteApi => {
	const state = {
		pageLevelData: {} as SeoPageConfig,
		tpaOverrides: [],
		corvidOverrides: [] as Array<any>,
		corvidItemPayload: undefined as SeoTagsPayload | undefined,
		seoStatusCode: DEFAULT_STATUS_CODE,
		dynamicPageData: [] as Array<any>,
		tpaOverridesListener: (tpaOverrides: Array<any>) => tpaOverrides,
	}
	if (multilingualApi) {
		siteLevelSeoData.context.currLangCode = multilingualApi.currentLanguage
		siteLevelSeoData.context.currLangIsOriginal = multilingualApi.isOriginalLanguage
	}

	const extractDynamicRouteData: ISeoSiteApi['extractDynamicRouteData'] = async (
		payload,
		currentCorvidOverrides = []
	) => {
		if (payload) {
			const api = await import('./api/api' /* webpackChunkName: "seo-api" */)
			const resolvedPageHeadData = await api.resolveDynamicPageHeadData(payload)
			const corvidOverrides = api.setExternalRouter(currentCorvidOverrides, resolvedPageHeadData)
			const dynamicPageData = await api.convertDynamicPageModel(resolvedPageHeadData)
			return {
				corvidOverrides,
				dynamicPageData,
			}
		}
	}

	return {
		getSiteLevelSeoData: (): SiteLevelSeoData => siteLevelSeoData,
		getSeoStatusCode: (): number => state.seoStatusCode,
		setPageData: async (pageLevelSeoData: SeoPageConfig) => {
			state.pageLevelData = pageLevelSeoData
		},
		resetTpaAndCorvidData: () => {
			state.tpaOverrides = []
			state.corvidOverrides = []
			state.tpaOverridesListener(state.tpaOverrides)
		},
		setCorvidTitle: async (title) => {
			const api = await import('./api/api' /* webpackChunkName: "seo-api" */)
			state.corvidOverrides = api.setTitle(state.corvidOverrides, title)
		},
		setCorvidLinks: async (links) => {
			const api = await import('./api/api' /* webpackChunkName: "seo-api" */)
			state.corvidOverrides = api.setLinks(state.corvidOverrides, links)
		},
		setCorvidMetaTags: async (metaTags) => {
			const api = await import('./api/api' /* webpackChunkName: "seo-api" */)
			state.corvidOverrides = api.setMetaTags(state.corvidOverrides, metaTags)
		},
		setCorvidStructuredData: async (structuredData) => {
			const api = await import('./api/api' /* webpackChunkName: "seo-api" */)
			state.corvidOverrides = api.setSchemas(state.corvidOverrides, structuredData)
		},
		setCorvidSeoStatusCode: (seoStatusCode) => {
			state.seoStatusCode = seoStatusCode // todo: need to grab this code and return it to tb-ssr as the return status for seo bots
		},
		setCorvidSeoTags: (payload) => {
			state.corvidItemPayload = {
				asNewPage: true,
				seoData: {},
				...payload,
			}
		},
		resetCorvidSeoTags: async () => {
			const api = await import('./api/api' /* webpackChunkName: "seo-api" */)
			state.corvidItemPayload = api.getDefaultItemPayload()
		},
		renderSEO: async () => {
			const api = await import('./api/api' /* webpackChunkName: "seo-api" */)
			const tags = api.getTags({
				siteLevelSeoData,
				pageLevelSeoData: state.pageLevelData,
				corvidOverrides: state.corvidOverrides,
				corvidItemPayload: state.corvidItemPayload,
				tpaOverrides: state.tpaOverrides,
				dynamicPageData: state.dynamicPageData,
			})
			const title = api.getTitle(tags)
			const staticMarkup = api.getStaticMarkup(tags).concat(siteLevelSeoData.customHeadTags)
			headUtils.setHead(staticMarkup, HeadContentType.SEO)
			api.setWindowTitle(title)
		},
		isInSEO: () => siteLevelSeoData.isInSEO,
		setTPAOverrides: async (payload) => {
			const api = await import('./api/api' /* webpackChunkName: "seo-api" */)
			const { title, fullTitle, description } = payload
			if (fullTitle) {
				state.tpaOverrides = api.setTitle(state.tpaOverrides, fullTitle)
			} else if (title) {
				state.tpaOverrides = api.setTitle(state.tpaOverrides, title)
			}
			if (description) {
				state.tpaOverrides = api.setDescription(state.tpaOverrides, description)
			}
			state.tpaOverridesListener(state.tpaOverrides)
		},
		setDynamicRouteOverrides: async (payload) => {
			if (payload) {
				const { corvidOverrides = state.corvidOverrides, dynamicPageData = state.dynamicPageData } =
					(await extractDynamicRouteData(payload, state.corvidOverrides)) || {}
				state.corvidOverrides = corvidOverrides
				state.dynamicPageData = dynamicPageData
			}
		},
		onTPAOverridesChanged: (cb) => (state.tpaOverridesListener = cb),
		extractDynamicRouteData,
	}
}

export const SeoSite = withDependencies(
	[named(SiteFeatureConfigSymbol, name), HeadContentSymbol, optional(MultilingualSymbol)],
	seoSiteFactory
)
