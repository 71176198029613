import { Identifier, IocContainer, ProviderCreator } from '@wix/thunderbolt-ioc'
import { IPageReflector, PagesSiteConfig } from './types'
import { name } from './symbols'
import {
	ClientApi,
	FeaturesConfig,
	IClientApi,
	IPageAssetsLoader,
	IPageWillMountHandler,
	IPropsStore,
	IStructureAPI,
	LifeCycle,
	PageAssets,
	PageAssetsLoaderSymbol,
	PageFeatureConfigSymbol,
	pageIdSym,
	Props,
	StructureAPI,
	MasterPageFeatureConfigSymbol,
	IPageDidUnmountHandler,
	ICompActionsStore,
	CompActionsSym,
} from '@wix/thunderbolt-symbols'
import { FeaturesLoaderSymbol, ILoadFeatures } from '@wix/thunderbolt-features'
import { errorPagesIds } from '@wix/thunderbolt-commons'

const bindFeaturesConfig = (container: IocContainer, featuresConfig: FeaturesConfig) => {
	Object.keys(featuresConfig).forEach((key) => {
		container
			.bind(PageFeatureConfigSymbol)
			.toConstantValue(featuresConfig[key as keyof FeaturesConfig])
			.whenTargetNamed(key)
	})
}

const createPageReflector = (pageContainer: IocContainer, masterPageReflector?: IPageReflector): IPageReflector => ({
	getAllImplementersOf<T>(identifier: Identifier): Array<T> {
		const pageInstances = pageContainer.getAll<T>(identifier)
		const masterPageInstance = masterPageReflector ? masterPageReflector.getAllImplementersOf<T>(identifier) : []
		return [...masterPageInstance, ...pageInstances]
	},
})

export const PageProvider: ProviderCreator<IPageReflector> = (container) => {
	const reflectors: Record<string, Promise<IPageReflector>> = {}

	const pageAssetsLoader = container.get<IPageAssetsLoader>(PageAssetsLoaderSymbol)
	const featuresLoader = container.get<ILoadFeatures>(FeaturesLoaderSymbol)
	const structureApi = container.get<IStructureAPI>(StructureAPI)
	const compActionsStore = container.get<ICompActionsStore>(CompActionsSym)
	const propsStore = container.get<IPropsStore>(Props)
	const clientApi = container.get<IClientApi>(ClientApi)
	const siteConfig = container.getNamed<PagesSiteConfig>(MasterPageFeatureConfigSymbol, name)

	const setupPageContainer = async (pageId: string, { features, props }: PageAssets) => {
		const pageContainer = container.createChild()
		await featuresLoader.loadPageFeatures(pageContainer, await features)
		bindFeaturesConfig(pageContainer, await props)

		pageContainer.bind<string>(pageIdSym).toConstantValue(pageId)
		return pageContainer
	}

	const createMasterPageReflectorIfNeeded = (pageId: string): Promise<IPageReflector> | Promise<undefined> => {
		if (pageId !== 'masterPage' && (siteConfig.nonPopupPages[pageId] || errorPagesIds[pageId])) {
			return createPage('masterPage')
		}

		return Promise.resolve(undefined)
	}

	const getPageDidUnmountHandler = ({
		pageId,
		props,
	}: {
		pageId: string
		props: PageAssets['props']
	}): IPageDidUnmountHandler => {
		const destroyPage = async () => {
			compActionsStore.setChildStore(pageId)
			propsStore.setChildStore(pageId)
			structureApi.cleanPageStructure(pageId)
			delete reflectors[pageId]
		}

		const triggerRenderOnComponents = async () => {
			// Trigger render on all of the masterPage structure component.
			// The underline component will be re-render only if one of the props was updated
			const emptyMap = Object.keys(await props).reduce((acc, compId) => ({ ...acc, [compId]: {} }), {})
			propsStore.update(emptyMap)
		}

		return {
			pageDidUnmount: pageId !== 'masterPage' ? destroyPage : triggerRenderOnComponents,
		}
	}

	const createPage = async (pageId: string) => {
		const assets = pageAssetsLoader.load(pageId)

		const masterPage = createMasterPageReflectorIfNeeded(pageId)
		const [pageContainer] = await Promise.all([
			setupPageContainer(pageId, assets),
			assets.props.then(({ render }) => propsStore.setChildStore(pageId, render.compProps)),
			structureApi.loadPageStructure(pageId),
		])
		compActionsStore.setChildStore(pageId, {})

		const loadComponentsPromise = clientApi.loadAndRegisterComponentsFromStructure()
		pageContainer.bind<IPageWillMountHandler>(LifeCycle.PageWillMountHandler).toConstantValue({
			pageWillMount: () => Promise.all([loadComponentsPromise, assets.css]),
		})

		pageContainer
			.bind<IPageDidUnmountHandler>(LifeCycle.PageDidUnmountHandler)
			.toConstantValue(getPageDidUnmountHandler({ pageId, props: assets.props }))

		return createPageReflector(pageContainer, await masterPage)
	}

	return (contextId, pageId = contextId) => {
		if (reflectors[contextId]) {
			return reflectors[contextId]
		}

		reflectors[contextId] = createPage(pageId)
		return reflectors[contextId]
	}
}
