import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LifeCycle } from '@wix/thunderbolt-symbols'
import { ConsentPolicySymbol } from './symbols'
import { ConsentPolicyBrowser } from './consentPolicyBrowser'

export const site: ContainerModuleLoader = (bind, bindAll) => {
	bindAll([ConsentPolicySymbol, LifeCycle.AppWillMountHandler], ConsentPolicyBrowser)
}

export * from './types'
export * from './symbols'
