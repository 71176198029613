import { withDependencies } from '@wix/thunderbolt-ioc'
import { IPageResourceFetcher } from './IPageResourceFetcher'
import { SiteAssetsClientSym, ViewerModel, ViewerModelSym, InitialAssetsSymbol } from '@wix/thunderbolt-symbols'
import { SiteAssetsClientAdapter } from 'thunderbolt-site-assets-client'
import { InitialAssets } from '../../types/InitialAssets'
import { errorPagesIds } from '@wix/thunderbolt-commons'

type ResourceFetcherFactory = (
	viewerModel: ViewerModel,
	siteAssetsClient: SiteAssetsClientAdapter,
	initialAssets: InitialAssets
) => IPageResourceFetcher

export const resourceFetcher: ResourceFetcherFactory = (viewerModel, siteAssetsClient, initialAssets) => ({
	fetchResource(pageCompId, resourceType) {
		const key = `${resourceType}_${pageCompId}`
		if (initialAssets.siteAssets[key]) {
			return Promise.resolve(initialAssets.siteAssets[key])
		}
		const {
			siteAssets: { modulesParams, siteScopeParams, beckyExperiments, staticHTMLComponentUrl },
			deviceInfo,
			mode: { qa: qaMode },
		} = viewerModel

		const moduleParams = modulesParams[resourceType]
		const isErrorPage = !!errorPagesIds[pageCompId]

		return siteAssetsClient.execute({
			moduleParams,
			siteScopeParams,
			pageCompId,
			beckyExperiments,
			deviceInfo,
			staticHTMLComponentUrl,
			qaMode,
			...(isErrorPage ? { pageCompId: isErrorPage ? 'masterPage' : pageCompId, errorPageId: pageCompId } : {}),
		})
	},
	getResourceUrl(pageCompId, resourceType): string {
		const {
			siteAssets: { modulesParams, siteScopeParams, beckyExperiments, staticHTMLComponentUrl },
			deviceInfo,
			mode: { qa: qaMode },
		} = viewerModel

		const moduleParams = modulesParams[resourceType]
		const isErrorPage = !!errorPagesIds[pageCompId]

		return siteAssetsClient.calcPublicModuleUrl({
			moduleParams,
			pageCompId,
			siteScopeParams,
			beckyExperiments,
			deviceInfo,
			staticHTMLComponentUrl,
			qaMode,
			...(isErrorPage ? { pageCompId: isErrorPage ? 'masterPage' : pageCompId, errorPageId: pageCompId } : {}),
		})
	},
})

export const PageResourceFetcher = withDependencies<IPageResourceFetcher>(
	[ViewerModelSym, SiteAssetsClientSym, InitialAssetsSymbol],
	resourceFetcher
)
