import { Store, Subscriber } from '@wix/thunderbolt-symbols'
import { getFullId } from '@wix/thunderbolt-commons'

type Subscribers<T> = Array<Subscriber<T>>

export const getStore = <T extends { [key: string]: any }>(): Store<T> => {
	const stores = {} as Record<string, T>
	let generalStore = {} as T

	const getPageStore = (id: string): T => {
		// Although we use Array.find the number of stores is 3 at most (page, masterPage and a lightbox) which means that we are still at O(1)
		const storesArray = Object.values(stores)
		const pageStore = storesArray.find((store) => store[id]) || storesArray.find((store) => store[getFullId(id)])

		return pageStore || generalStore
	}

	const subscribers: Subscribers<T> = []
	const update = (partialStore: T) => {
		const partialStoreWithCompleteEntries = Object.entries(partialStore).reduce((acc, [compId, value]) => {
			const pageStore = getPageStore(compId)
			pageStore[compId as keyof T] = { ...pageStore[compId], ...value }

			return { ...acc, [compId]: pageStore[compId] }
		}, {} as T)

		subscribers.forEach((cb) => {
			cb(partialStoreWithCompleteEntries)
		})
	}

	return {
		get: (id: string) => {
			const pageStore = getPageStore(id)!
			return pageStore[id]
		},
		setChildStore: (pageId: string, store?: T) => {
			if (store) {
				stores[pageId] = { ...store }

				// Apply changes made before adding the page to the store
				generalStore = Object.entries(generalStore).reduce((acc, [compId, value]) => {
					if (store[compId] || store[getFullId(compId)]) {
						stores[pageId][compId as keyof T] = { ...stores[pageId][compId], ...value }
						return acc
					}

					return { ...acc, [compId]: value }
				}, {} as T)
			} else {
				delete stores[pageId]
			}
		},
		getEntireStore: () => Object.assign({}, ...Object.values(stores), generalStore),
		update,
		subscribeToChanges: (cb: Subscriber<T>) => {
			subscribers.push(cb)
		},
	}
}
