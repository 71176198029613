import * as clientApi from './client-api/client-api'
import * as pageInitializer from './pageInitializer/pageInitializer'
import * as componentsLoader from '@wix/thunderbolt-components-loader'
import * as stores from './stores'
import * as thunderbolt from './thunderbolt/Thunderbolt'
import * as translations from './translations/translations'
import * as compEventsRegistrar from './compEventsRegistrar/compEventsRegistrar'
import * as compRefs from './compRefs/compRefs'
import * as context from './thunderbolt/Context'
import * as pageAssetsLoader from './page/PageAssetsLoader'

import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'

const featureLoaders = [
	clientApi,
	compEventsRegistrar,
	componentsLoader,
	compRefs,
	context,
	pageInitializer,
	pageAssetsLoader,
	stores,
	thunderbolt,
	translations,
]

export const site: ContainerModuleLoader = (bind, bindAll) =>
	featureLoaders.forEach(({ site: siteLoader }) => siteLoader(bind, bindAll))
