import { multi, withDependencies } from '@wix/thunderbolt-ioc'
import {
	BrowserWindow,
	BrowserWindowSymbol,
	CurrentRouteInfoSymbol,
	IAppDidMountHandler,
	ILinkClickHandler,
	LinkClickHandlerSymbol,
	NavigationClickHandlerSymbol,
} from '@wix/thunderbolt-symbols'
import { IPageProvider, PageProviderSymbol } from 'feature-pages'
import { ICurrentRouteInfo } from 'feature-router'

type HTMLElementTarget = HTMLElement | null
const getAnchorTarget = (event: MouseEvent) => {
	let eTarget = event.target as HTMLElementTarget

	while (eTarget && (!eTarget.tagName || eTarget.tagName.toLowerCase() !== 'a')) {
		eTarget = eTarget.parentNode as HTMLElementTarget
	}
	return eTarget
}

const cancelEvent = (e: MouseEvent) => {
	e.stopPropagation()
	e.preventDefault()
	return false
}

const clickHandlerRegistrarFactory = (
	browserWindow: BrowserWindow,
	navigationHandler: ILinkClickHandler,
	siteLinkClickHandlers: Array<ILinkClickHandler>,
	pageProvider: IPageProvider,
	currentPageInfo: ICurrentRouteInfo
): IAppDidMountHandler => ({
	appDidMount: () => {
		const onClick = async (e: MouseEvent) => {
			let pageLinkClickHandlers: Array<ILinkClickHandler> = []
			const routerInfo = currentPageInfo.getCurrentRouteInfo()
			if (routerInfo) {
				const pageId = routerInfo.pageId
				const pageReflector = await pageProvider(pageId)
				pageLinkClickHandlers =
					pageReflector.getAllImplementersOf<ILinkClickHandler>(LinkClickHandlerSymbol) || []
			}
			const anchorTarget = getAnchorTarget(e)
			if (!anchorTarget) {
				return
			}
			if (anchorTarget.getAttribute('data-cancel-link')) {
				return cancelEvent(e)
			}
			for (const handler of [...siteLinkClickHandlers, ...pageLinkClickHandlers, navigationHandler]) {
				const didHandle = handler.handleClick(anchorTarget)
				if (didHandle) {
					e.preventDefault()
					e.stopPropagation()
					return
				}
			}
		}
		browserWindow!.addEventListener('click', onClick)
	},
})

export const ClickHandlerRegistrar = withDependencies(
	[
		BrowserWindowSymbol,
		NavigationClickHandlerSymbol,
		multi(LinkClickHandlerSymbol),
		PageProviderSymbol,
		CurrentRouteInfoSymbol,
	],
	clickHandlerRegistrarFactory
)
