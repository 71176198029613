import { BiStore, WixBiSession } from '@wix/thunderbolt-symbols'

export const getBiStore = (wixBiSession: WixBiSession): BiStore => {
	const {
		initialTimestamp,
		dc,
		visitorId,
		siteMemberId,
		viewerSessionId,
		requestId,
		is_rollout,
		isCached,
		msId,
	} = wixBiSession

	return {
		msid: msId,
		visitorId,
		siteMemberId,
		viewerSessionId,
		requestId,
		initialTimestamp,
		dc,
		is_rollout,
		isCached,
		is_headless: false,
	}
}
