import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	IPageWillMountHandler,
	FeatureStateSymbol,
	CurrentRouteInfoSymbol,
} from '@wix/thunderbolt-symbols'
import { SeoPageConfig, ISeoSiteApi } from './types'
import { name, SeoSiteSymbol } from './symbols'
import { IFeatureState } from 'thunderbolt-feature-state'
import { ICurrentRouteInfo } from 'feature-router'

type SeoFeatureState = {
	didNavigate: boolean
}

const initialState: SeoFeatureState = {
	didNavigate: false,
}

const shouldLoad = ({ didNavigate }: SeoFeatureState): boolean => didNavigate || !process.env.browser

const seoPageFactory = (
	pageLevelSeoData: SeoPageConfig,
	featureState: IFeatureState<SeoFeatureState>,
	seoApi: ISeoSiteApi,
	routeApi: ICurrentRouteInfo
): IPageWillMountHandler => {
	return {
		pageWillMount: async () => {
			const state = featureState.get() || initialState
			seoApi.setPageData(pageLevelSeoData)
			seoApi.resetTpaAndCorvidData()
			await seoApi.setDynamicRouteOverrides(routeApi.getCurrentRouteInfo()?.dynamicRouteData)
			if (!shouldLoad(state)) {
				featureState.update(() => ({ didNavigate: true }))
				return
			}
			seoApi.renderSEO()
		},
	}
}

export const SeoPage = withDependencies(
	[named(PageFeatureConfigSymbol, name), named(FeatureStateSymbol, name), SeoSiteSymbol, CurrentRouteInfoSymbol],
	seoPageFactory
)
