import { WixBiSession } from '@wix/thunderbolt-symbols'
import { commonBiLoggerFactory, getBiStore } from '@wix/thunderbolt-commons'
import { ISessionManager } from 'feature-session-manager'

export const createBusinessLoggerFactory = (wixBiSession: WixBiSession, sessionManager: ISessionManager) => {
	return commonBiLoggerFactory.createBaseBiLoggerFactory({
		biStore: getBiStore(wixBiSession),
		sessionManager,
	})
}
