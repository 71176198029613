import { multi, named, optional, withDependencies } from '@wix/thunderbolt-ioc'
import {
	CurrentRouteInfoSymbol,
	IAppWillLoadPageHandler,
	IAppDidLoadPageHandler,
	IStructureAPI,
	LifeCycle,
	SiteFeatureConfigSymbol,
	StructureAPI,
} from '@wix/thunderbolt-symbols'
import { resolveUrl } from './resolveUrl'
import { name, RoutingMiddleware, UrlHistoryManagerSymbol } from './symbols'
import { ICurrentRouteInfo, IRoutingMiddleware, IRouter, IRoutingConfig, RouteInfo, IUrlHistoryManager } from './types'
import { PageTransitionsCompletedSymbol, IPageTransitionsCompleted } from 'feature-page-transitions'
import { IPageProvider, IPageReflector, PageProviderSymbol } from 'feature-pages'
import { taskify, errorPagesIds } from '@wix/thunderbolt-commons'

const emptyMiddleware: IRoutingMiddleware = {
	handle: async (routeInfo) => routeInfo,
}

const onPageTransitionsCompleted = (pageId: string, contextId: string, pageReflector: IPageReflector) => {
	const handlers = pageReflector.getAllImplementersOf<IAppDidLoadPageHandler>(LifeCycle.AppDidLoadPageHandler)
	const [pageTransitionsImp] = pageReflector.getAllImplementersOf<IPageTransitionsCompleted>(
		PageTransitionsCompletedSymbol
	)

	if (pageTransitionsImp) {
		pageTransitionsImp.onPageTransitionsCompleted(() => {
			handlers.map((handler) => handler.appDidLoadPage({ pageId, contextId }))
		})
	} else {
		handlers.map((handler) => handler.appDidLoadPage({ pageId, contextId }))
	}
}

const getContextId = ({ pageId, relativeUrl }: RouteInfo): string => {
	const [, additionalRoute] = relativeUrl?.match(/\.\/.*?\/(.*$)/) || []
	return additionalRoute ? `${pageId}_${additionalRoute}` : pageId
}

const routerFactory = (
	routingConfig: IRoutingConfig,
	structureApi: IStructureAPI,
	pageProvider: IPageProvider,
	dynamicRoutingMiddleware: IRoutingMiddleware = emptyMiddleware,
	protectedRoutingMiddleware: IRoutingMiddleware = emptyMiddleware,
	appWillLoadPageHandlers: Array<IAppWillLoadPageHandler>,
	currentRouteInfo: ICurrentRouteInfo,
	urlHistoryManager: IUrlHistoryManager
): IRouter => {
	const handleStaticRoute = async (routeInfo: RouteInfo) => {
		currentRouteInfo.updateCurrentRouteInfo(routeInfo)
		urlHistoryManager.pushUrlState(routeInfo.parsedUrl)

		const pageId = routeInfo.pageId
		const contextId = getContextId(routeInfo)
		// Create the reflector for the pageId with the contextId
		const pageReflectorPromise = pageProvider(contextId, pageId)
		await Promise.all(
			appWillLoadPageHandlers.map((handler) => taskify(() => handler.appWillLoadPage({ pageId, contextId })))
		)

		const pageReflector = await pageReflectorPromise
		await structureApi.addPageAndRootToRenderedTree(pageId)

		onPageTransitionsCompleted(pageId, contextId, pageReflector)

		return routeInfo
	}

	const pageJsonFileNameMiddleware: IRoutingMiddleware = {
		handle: async (routeInfo) => {
			if (!routeInfo.pageId) {
				throw new Error(`did not find the pageId for the requested url ${routeInfo.parsedUrl?.pathname}`)
			}

			const isErrorPage = errorPagesIds[routeInfo.pageId!]
			const pageJsonFileName = isErrorPage ? routeInfo.pageId : routingConfig.pages[routeInfo.pageId!]

			return {
				...routeInfo,
				pageJsonFileName,
			}
		},
	}

	const customNotFoundPageMiddleware: IRoutingMiddleware = {
		handle: async (routeInfo) => {
			if (
				(!routeInfo.pageId || routeInfo.pageId === errorPagesIds.__404__dp) &&
				routingConfig.customNotFoundPage?.pageId
			) {
				return {
					...routeInfo,
					pageId: routingConfig.customNotFoundPage?.pageId,
					relativeUrl: routingConfig.customNotFoundPage?.pageRoute,
				}
			}
			return routeInfo
		},
	}

	let redirectCounter = 0

	const navigate = async (url: string, anchorDataId?: string): Promise<boolean> => {
		const currentRoute = currentRouteInfo.getCurrentRouteInfo()
		let routeInfo: Partial<RouteInfo> | null = resolveUrl(url, routingConfig, currentRoute)

		routeInfo = await dynamicRoutingMiddleware.handle(routeInfo)

		if (routeInfo && routeInfo.redirectUrl) {
			if (redirectCounter < 4) {
				redirectCounter++
				return navigate(routeInfo.redirectUrl)
			}
			redirectCounter = 0
			return false
		} else {
			redirectCounter = 0
		}

		routeInfo = routeInfo && (await customNotFoundPageMiddleware.handle(routeInfo))
		routeInfo = routeInfo && (await pageJsonFileNameMiddleware.handle(routeInfo))
		routeInfo = routeInfo && (await protectedRoutingMiddleware.handle(routeInfo))

		if (!routeInfo) {
			return false
		}
		// TODO: add dynamic and in due time TPA :)
		if (currentRoute?.pageId === routeInfo.pageId) {
			return false
		}
		if (!routeInfo.pageJsonFileName) {
			throw new Error(`did not find the json file name for pageId ${routeInfo.pageId}`)
		}

		if (anchorDataId) {
			routeInfo.anchorDataId = anchorDataId
		}

		const finalRouteInfo = routeInfo as RouteInfo

		await handleStaticRoute(finalRouteInfo)
		return true
	}

	return {
		navigate,
	}
}

export const Router = withDependencies(
	[
		named(SiteFeatureConfigSymbol, name),
		StructureAPI,
		PageProviderSymbol,
		optional(RoutingMiddleware.Dynamic),
		optional(RoutingMiddleware.Protected),
		multi(LifeCycle.AppWillLoadPageHandler),
		CurrentRouteInfoSymbol,
		UrlHistoryManagerSymbol,
	],
	routerFactory
)
