import { ContainerModuleLoader, withDependencies } from '@wix/thunderbolt-ioc'
import {
	IAppWillMountHandler,
	Context,
	IContext,
	Fetch,
	Language,
	Translate,
	ITranslate,
	ILanguage,
	IFetchApi,
	LifeCycle,
	InitialAssetsSymbol,
} from '@wix/thunderbolt-symbols'
import { getTranslationUrl } from './translationsUrl'
import { Translations } from '../../types/Translations'
import { InitialAssets } from '../../types/InitialAssets'

const translationsImpl = (fetchApi: IFetchApi, language: ILanguage, initialAssets: InitialAssets): ITranslate => {
	let translations: Translations = {}
	const translate: ITranslate = (featureNamespace, key, defaultValue) =>
		(translations[featureNamespace] && translations[featureNamespace][key]) || defaultValue

	if (initialAssets.translations) {
		translations = initialAssets.translations
	} else {
		const url = getTranslationUrl(language.translationsBaseUrl, language.userLanguage)
		fetchApi.getJson<Translations>(url).then((fetchedTranslations) => {
			translations = fetchedTranslations
		})
	}

	return translate
}

const translateBinder = (translate: ITranslate, context: IContext): IAppWillMountHandler => ({
	async appWillMount() {
		context.extend({ translate })
	},
})

const TranslationsImpl = withDependencies([Fetch, Language, InitialAssetsSymbol], translationsImpl)
const TranslateBinder = withDependencies([Translate, Context], translateBinder)

export const site: ContainerModuleLoader = (bind) => {
	bind(Translate).to(TranslationsImpl)
	bind(LifeCycle.AppWillMountHandler).to(TranslateBinder)
}
